<template>
  <div class="row">
    <div class="flex md1"></div>
    <div class="flex md10">
      <va-card>
        <va-card-content>
          <div class="row justify--center" v-if="errors.length !== 0">
            <div class="flex lg8">
              <va-alert color="danger" icon="info" class="mb-1">
                <ul>
                  <li v-for="(error, index) in errors" :key="index">
                    {{ error }}
                  </li>
                </ul>
              </va-alert>
            </div>
          </div>
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="flex md12">
                <va-input
                  v-model="form.currentPassword"
                  label="Ancien mot de passe"
                  type="password"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="flex md12">
                <va-input
                  v-model="form.password"
                  label="Nouveau mot de passe"
                  type="password"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="flex md12">
                <va-input
                  v-model="form.password_confirmation"
                  label="Confirmer le nouveau mot de passe"
                  type="password"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="flex md12">
                <va-button type="submit" gradient size="large" :loading="loadingForm">Changer le mot de passe</va-button>
              </div>
            </div>
          </form>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Settings",
  data () {
    return {
      errors: [],
      loadingForm: false,
      form: {
        currentPassword: '',
        password: '',
        // eslint-disable-next-line @typescript-eslint/camelcase
        password_confirmation: '',
      }
    }
  },
  methods: {
    async submitForm() {
      try {
        this.loadingForm = true
        await axios.post('/api/admin/update-password', this.form)
        this.$vaToast.init({
          color: 'success',
          message: 'Mot de passe mis à jour',
          duration: '30000',
        })
        this.form = {
          currentPassword: '',
          password: '',
          // eslint-disable-next-line @typescript-eslint/camelcase
          password_confirmation: '',
        }
        this.errors = []
      } catch (e) {
        this.errors = Object.keys(e.response.data.errors).map(key => e.response.data.errors[key][0])
      } finally {
        this.loadingForm = false
      }
    }
  }
}
</script>

<style scoped>

</style>
